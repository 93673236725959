import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 96px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);
 

function Logo() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
      <LogoWrapper to="/">
             <img style={{height:44}} src="/static/images/logo.svg" />

      </LogoWrapper>
  );
}

export default Logo;
