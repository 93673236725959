import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

 
// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);  

const baseRoutes = [
   
  {
    path: '',
    element: <Navigate to="/" replace />
  },
  {
    path: '*',
    element: <Status404 />
  },
  
  {
    path: '*',
    element: <Status404 />
  }
];

export default baseRoutes;
