import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
 

const BottomWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};
    display: flex;
    align-items: center;
    justify-content: center;
`
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    background:#034854;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  background:#034854;
  flex: 1;
  padding: 20px;
`
);

function LoginBasic() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Logo />
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
            
              {method === 'JWT' && <JWTLogin />}
              
              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Don’t have an account, yet?')}
                </Typography>{' '}
                <Link component={RouterLink} to="/register">
                  <b>Sign up here</b>
                </Link>
              </Box>
              
            </Card>
{/*             

            <Alert severity="error">
              {t(
                'Learn how to switch between auth methods by reading the section we’ve prepared in the documentation.'
              )}
            </Alert> */}
          </Container>
        </TopWrapper>
        <div className="ed_row clearfix v5 mdlfooter1v5" style={{backgroundColor: '#2b2e31'}}>
    <div className="pm mdl_footer1v5 clearfix" style={{color: '#888888', maxWidth:'1024px', width:'100%', padding:10, margin:'0 auto'}}>
        <p style={{color: '#a7a8b6'}}><strong>LEGAL:</strong> This website is operated by DMA Capitals Limited Registration No: LL18857 
        <br />Registered Address: Lot No. 20, Level 1, Lazenda Commercial Center Phase 3, Jalan OKK Abdullah, 87000 Federal Territory of Labuan, Malaysia
        <br />Operational Address: Office No 648, 6th Floor, Business Village, Block A Dubai, UAE
<br /><br />
        </p><p style={{color: '#a7a8b6'}}><strong>Risk Warning:</strong> Trading Forex and Leveraged Financial Instruments involves significant risk and can result in the loss 
        of your invested capital. Your capital is at risk. Leveraged products may not be suitable for everyone. 
<br /><br />        
        </p><p style={{color: '#a7a8b6'}}><strong>Restricted Regions:</strong> DMA Capitals does not offer its services to residents of certain jurisdictions such as USA, Canada, Japan, Iran, 
        Cuba, Sudan, Syria and North Korea.</p>
<br /><br />  
<div>   <a style={{color:"#fff", paddingLeft:4, paddingRight:4}} href="https://dmacapitals.com/uploads/terms_and_conditions.pdf">Terms &amp; Conditions</a>|
        <a style={{color:"#fff", paddingLeft:4, paddingRight:4}} href="https://dmacapitals.com/uploads/risk_disclosure_and_warning_notice.pdf">Risk Warning</a>|
        <a style={{color:"#fff", paddingLeft:4, paddingRight:4}} href="https://dmacapitals.com/uploads/anti_money_laundering.pdf">Anti Money Laundering</a>|
        <a style={{color:"#fff", paddingLeft:4, paddingRight:4}} href="https://dmacapitals.com/uploads/privacy_agreement.pdf">Privacy Policy</a>|
        <a style={{color:"#fff", paddingLeft:4, paddingRight:4}} href="https://dmacapitals.com/complain" title="complain">Complaints</a>
    </div>
</div>
<div className="ed_row clearfix v5 mdlfooterv5" style={{backgroundColor: '#3c4043'}}>
    <div className="pm mdl_footerv5 clearfix" style={{ maxWidth:'1024px', width:'100%', padding:10, margin:'0 auto'}}>
        <div>
            <div style={{color: "#ffffff"}}><b>Account Department:</b> +971 56 994-3431</div>
            <div className="copyright ed_text" style={{paddingTop:0, color:"#fff"}}>© 2024 DMA Capitals Limited. ALL RIGHTS ARE RESERVED.</div>
            
        </div>
    </div>
</div>
</div>
      </MainContent>
    </>
  );
}

export default LoginBasic;
