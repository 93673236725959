import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Docs

const Deposit = Loader(
  lazy(() => import('src/content/moneyMovement/deposit'))
);
const Withdraw = Loader(
  lazy(() => import('src/content/moneyMovement/withdraw'))
);

const TransferInternal = Loader(
  lazy(() => import('src/content/moneyMovement/TransferInternal'))
);

const TransferExternal = Loader(
  lazy(() => import('src/content/moneyMovement/TransferExternal'))
);


const documentationRoutes = [
  {
    path: 'deposit',
    element: <Deposit />
  },
  {
    path: 'transfer-internal',
    element: <TransferInternal />
  },
  {
    path: 'transfer-external',
    element: <TransferExternal />
  },
  {
    path: 'withdraw',
    element: <Withdraw />
  },
];

export default documentationRoutes;
