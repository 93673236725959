import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Docs

const ChampionContest = Loader(
  lazy(() => import('src/content/championContest/index'))
);


const championContestRoutes = [
  {
    path: '',
    element: <ChampionContest />
  },
];

export default championContestRoutes;
