import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
 
const Analytics = Loader(
  lazy(() => import('src/content/dashboards/Analytics'))
);
const Profile = Loader(
  lazy(() => import('src/content/dashboards/Analytics/profile'))
);

const dashboardsRoutes = [
  {
    path: '',
    element: <Analytics />
  },
  {
    path: 'profile',
    element: <Profile tab='edit_profile' />
  },
  {
    path: 'profile/password',
    element: <Profile tab='change_password' />
  }
];

export default dashboardsRoutes;
