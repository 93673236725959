import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Docs

 
const SocialTrading =  Loader(
  lazy(() => import('src/content/tradeTerminal/SocialTrading'))
);

const socialTradingRoutes = [
  
  {
    path: '',
    element: <SocialTrading />
  },
];

export default socialTradingRoutes;
