import { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";

import { Avatar, Box, Typography, useTheme } from "@mui/material";

import axiosInt from "src/utils/axios";

function SidebarTopSection(props) {
  const theme = useTheme();

  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [totalBalance, setTotalBalance] = useState<any>(0);

  const getBalance = async () => {
    let resp: any = await axiosInt.get("/api/trading_account_balances");
    let ttl_bl = resp.data.ttl_bl || "0.00";
    
    if (resp.data.ndbStatus === 1 || resp.data.ndbStatus === "1") {
      props.setNdbStatus(true);
    } else {
      props.setNdbStatus(false);
    }
    setTotalBalance(parseFloat(ttl_bl).toFixed(2));
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <Box
      sx={{
        textAlign: "center",
        mx: 2,
        pt: 1,
        position: "relative",
      }}
    >
      <Avatar
        sx={{
          width: 68,
          height: 68,
          mb: 2,
          mx: "auto",
        }}
        alt={user.name}
        src={user.avatar}
      />

      <Typography
        variant="h4"
        sx={{
          color: `${theme.colors.alpha.trueWhite[100]}`,
        }}
      >
        {user.name}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: `${theme.colors.alpha.trueWhite[70]}`,
        }}
      >
        Total Balance: ${totalBalance}
      </Typography>
    </Box>
  );
}

export default SidebarTopSection;
