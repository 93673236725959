import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Rewards = Loader(
  lazy(() => import('src/content/rewards/rewards'))
);

const IbRewards = Loader(
  lazy(() => import('src/content/rewards/ibRewards'))
);

const documentationRoutes = [
  {
    path: 'rewards',
    element: <Rewards />
  }, {
    path: 'ibRewards',
    element: <IbRewards />
  },
];

export default documentationRoutes;
