import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Docs

const IbDetails = Loader(
  lazy(() => import('src/content/IbDetails'))
);


const documentationRoutes = [
  {
    path: '',
    element: <IbDetails />
  },
];

export default documentationRoutes;
