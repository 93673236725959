import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account
 
const LoginBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);

// const RegisterCover = Loader(
//   lazy(() => import('src/content/pages/Auth/Register/Cover'))
// );
const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Basic'))
);
const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const accountRoutes = [
  {
    // {/**/}
    // </Guest>
    path: '',
    element: (
      <Guest>
        <LoginBasic />
        </Guest>
    )
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterBasic />
        </Guest>
      
    )
  },{
    path: 'user/signup',
    element: (
      <Guest>
        <RegisterBasic />
        </Guest>
      
    )
  },
  {
    path: 'forgot',
    element: (
      <Guest>
        <RecoverPassword />
        </Guest>
      
    )
  },
  // {
  //   path: 'login-basic',
  //   element: <LoginBasic />
  // },
   
  // {
  //   path: 'register',
  //   element: (
  //     <Guest>
  //       <RegisterBasic />
  //     </Guest>
  //   )
  // },
   
];

export default accountRoutes;
